.experience-heading {
	text-align: center;
	margin-bottom: 20px;
  }
  
  .timeline {
	position: relative;
	margin: 0 auto;
	max-width: 800px;
  }
  
  .event {
	position: relative;
	padding: 20px;
	margin: 20px 0;
	border-left: 2px solid #4db5ff;
  }
  
  .event-date {
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .event-title {
	font-size: 1.2em;
	margin-bottom: 5px;
  }
  
  .event-description {
	font-size: 0.9em;
  }
  
  .event-company {
	font-weight: bold;
	font-size: 1.2em;
	margin-bottom: 5px;
  }
  .event-description ul {
	list-style-type: none; /* Remove default bullets */
	margin-bottom: 10px; /* Add some space between items */
	padding-left: 20px; /* Add left padding for indentation */
  }
  
  .event-description li {
	margin-bottom: 5px; /* Add space between each list item */
  }
  
  .event-description li::before {
	content: '•'; /* Use a custom bullet character */
	color: #fff; /* Set the bullet color to white */
	font-size: 1.2em; /* Adjust the bullet size if needed */
	display: inline-block; /* Ensures the bullet is on the same line as the text */
	width: 1em; /* Adjust the width of the custom bullet character */
	margin-left: -1em; /* Adjust the negative margin to create space after the bullet */
  }
  
  .event-logo {
	position: absolute;
	left: -150px; /* Adjust as needed based on your design */
	top: 50px; /* Adjust to position it near the top */
	transform: translateY(-50%);
  }
  
  .event-logo img {
	max-width: 125px; /* Adjust the maximum width of the logo */
	height: auto;
  }
  
  
  .event-details {
	margin-left: 0; /* Reset margin for event details */
	/* ... other styles */
  }
  
  .event-logo2 {
	position: absolute;
	left: -230px; /* Adjust as needed based on your design */
	top: 50px; /* Adjust to position it near the top */
	transform: translateY(-50%);
  }
  
  .event-logo2 img {
	max-width: 200px; /* Adjust the maximum width of the logo */
	height: auto;
  }
  
  