
header {
	height: 100vh;
	padding-top: 7rem;
	overflow: hidden;
}

.header_container{
	text-align: center;
	height: 100%;
	position: relative;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.cta a {
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 5px;
    transition: transform 0.3s, opacity 0.3s;
}

.cta a:hover {
    transform: scale(1.1); /* Increase size on hover */
    opacity: 0.8; /* Reduce opacity on hover */
}

.header_socials{
	font-size: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.me-container {
    position: relative;
    width: 22rem;
    height: 30rem;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    overflow: hidden;
}

.me-container img {
    max-width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s;
}

.me-container img:hover {
    transform: scale(1.1); /* Increase size on hover */
}

.me-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)); /* Adjust the gradient and opacity as needed */
    z-index: 1; /* Ensure the gradient layer is above the image */
    pointer-events: none; /* Allow interaction with the image beneath */
}


.scroll_down{
	position: absolute;
	right: -2.3rem;
	bottom: 30rem;
	transform: rotate(90deg);
	font-weight: 300;
	font-size: 0.9rem;
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
	header {
		height: 68vh;
	}
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
	header{
		height: 100vh;
	}

	.scroll_down{
		display:none;
	}
}