.about-blurb {
    width: 600px;
    height: 300px;
    background-color: var(--color-primary);
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    display: flex; /* Add flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center vertically */
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.about-section {
	position: relative;
  }
  
  .celtics-logo {
	position: absolute;
	top: 10px;
	right: 10px;
	max-width: 200px;
	height: auto;
  }
  